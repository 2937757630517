.dnapatternimageIcon {
  width: 22.188rem;
  position: absolute;
  margin: 0 !important;
  top: 0;
  right: 0;
  height: 58.125rem;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  z-index: 0;
}

.dnaDecoderlogoIcon {
  width: 22.5rem;
  position: relative;
  height: 2.5rem;
  object-fit: cover;
}

.tagline {
  margin: 0;
  position: relative;
  font-weight: 500;
}

.infoicon {
  width: 1.5rem;
  position: relative;
  height: 1.5rem;
  overflow: hidden;
  flex-shrink: 0;
}

.action {
  margin: 0;
  position: relative;
  font-size: 1rem;
  font-weight: 500;
  font-family: "IBM Plex Sans";
  color: #fff;
  text-align: left;
}

.header,
.helpbutton,
.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.helpbutton {
  cursor: pointer;
  border: 0;
  padding: 0.5rem 1rem;
  background-color: #cddc57;
  border-radius: 8px;
  justify-content: flex-start;
  gap: 0.5rem;
}

.header,
.navbar {
  align-self: stretch;
  justify-content: space-between;
}

.header {
  background-color: #fff;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.25rem 5rem;
  padding-inline: clamp(16px, 4.16vw, 160px);
  text-align: left;
  font-size: 1.125rem;
  color: #000;
  font-family: "IBM Plex Sans";
}

.description,
.headingtext {
  margin: 0;
  position: relative;
  font-weight: 400;
  display: inline-block;
}

.headingtext {
  align-self: stretch;
  height: 3rem;
  font-size: inherit;
  line-height: 100%;
  font-family: inherit;
  background: linear-gradient(90deg, #ffa63e, #b03d79);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.description {
  width: 26.438rem;
  height: 2.75rem;
  font-size: clamp(14px, 0.83vw, 32px);
  line-height: 140%;
  font-family: Lato;
  color: #000;
  text-align: center;
}

.mobilenumberinputbox {
  border: 0;
  background-color: transparent;
  width: 100%;
  font-family: "IBM Plex Sans";
  font-size: 1rem;
  color: #292929;
  max-width: 25rem;
}

.bodyhead,
.form,
.submitbutton {
  display: flex;
  align-items: center;
}

.submitbutton {
  cursor: pointer;
  font-size: clamp(12px, 0.72vw, 16px) !important;
  border: 0;
  padding: 10px 40px;
  width: 50%;
  background-color: transparent;
  border-radius: 6px;
  background: linear-gradient(
    90deg,
    #cddc57 0.01%,
    #f19d5e 36.5%,
    #c53476 69.5%,
    #ae3a7a
  );
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  color: #fff;
  font-family: "IBM Plex Sans";
  transition: all 1s ease-in-out;
}

.submitbutton:hover {
  background: linear-gradient(
    90deg,
    #ae3a7a 0.01%,
    #c53476 36.5%,
    #f19d5e 69.5%,
    #cddc57
  ) !important;
}

.changeNumberBtn {
  font-size: clamp(12px, 0.72vw, 16px);
  text-decoration: none;
  text-align: center;
  display: inline-block;
  position: relative;
  z-index: 1;
  padding: 8px 18px;
  border-radius: 6px;
  overflow: hidden;
  font-family: "IBM Plex Sans";
  background: linear-gradient(#faf4ef, #faf4ef) padding-box,
    linear-gradient(to right, #ffa63e, #b03d79) border-box;
  border-radius: 8px;
  border: 1px solid transparent;
  align-self: stretch;
}

@media (max-width: 768px) {
  .changeNumberBtn {
    border: 0 !important;
    background: transparent;
  }
}

.btnText {
  font-size: clamp(12px, 0.72vw, 16px);
  text-align: center;
  background: linear-gradient(90deg, #ffa63e 0%, #b03d79 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 500;
}

.bodyhead,
.form {
  align-self: stretch;
  flex-direction: column;
  justify-content: flex-start;
}

.form {
  margin: 0;
  padding: 0 6.25rem;
  gap: 1.25rem;
}

.bodyhead {
  gap: 1.5rem;
}

.reporticon {
  width: 1.75rem;
}

@media (max-width: 500px) {
  .reporticon {
    width: 1.5rem;
  }
}

.patientname,
.visitid {
  margin: 0;
  align-self: stretch;
  position: relative;
  line-height: 100%;
  display: inline-block;
}

.patientname {
  overflow: hidden;
  color: #171c26;
  text-overflow: ellipsis;
  font-family: "Lato", sans-serif;
  font-size: clamp(10px, 0.83vw, 20px);
  font-weight: 500;
  line-height: 150%;
}

.visitid {
  height: 0.875rem;
  font-size: clamp(12px, 0.72vw, 28px);
  color: #00435d;
}

.patientnameandid {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.313rem;
}

.dateandtime {
  margin: 0;
  height: 0.875rem;
  flex: 1;
  position: relative;
  font-size: clamp(10px, 0.72vw, 20px);
  line-height: 100%;
  font-weight: 500;
  font-family: inherit;
  color: #464f60;
  text-align: right;
  display: inline-block;
}

.row {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
}

.casetype {
  margin: 0;
  height: 1rem;
  position: relative;
  line-height: 100%;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.casetypechip {
  border-radius: 3px;
  background-color: #ffecd7;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.375rem;
}

.barcode {
  margin: 0;
  position: relative;
  line-height: 100%;
  display: inline-block;
}

.chipscontainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
  color: #ff9a29;
}

.actionname1 {
  margin: 0;
  position: relative;
  line-height: 100%;
  display: inline-block;
}

.actionname1 {
  font-size: clamp(14px, 0.83vw, 32px);
  font-family: Lato;
  text-align: center;
  background: linear-gradient(90deg, #ffa63e 0%, #b03d79 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.details,
.downloadreportbutton {
  display: flex;
  justify-content: center;
}

.downloadreportbutton {
  cursor: pointer;
  border: 0;
  padding: 0.5rem 1.25rem;
  background: transparent;
  flex-direction: row;
  align-items: center;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(to right, #ffa63e, #b03d79) border-box;
  border-radius: 8px;
  border: 2px solid transparent;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.details {
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
  padding-inline: clamp(16px1 0.04v, 40px);
  padding-block: clamp(16px1 0.04v, 40px);
  row-gap: clamp(14px, 0.83vw, 32px);
}

.reportcard {
  flex: 1;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1.25rem;
  box-sizing: border-box;
  gap: 0.688rem;
  height: 100%;
  max-width: 25.5rem;
  padding-inline: clamp(12px1 0.04v, 40px);
  padding-block: clamp(12px1 0.04v, 40px);
  position: relative;
  padding-bottom: 4rem;
}
@media (min-width: 1920px) {
  .reportcard {
    max-width: 100% !important;
  }
}

.bodycontainer,
.container,
.reportcardscontainer {
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reportcardscontainer {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.5rem;
  text-align: left;
  height: 100%;
  font-size: clamp(14px, 0.83vw, 32px);
}

.bodycontainer,
.container {
  flex-direction: column;
}

.bodycontainer {
  padding: 0 5rem;
  gap: 4rem;
  padding-inline: clamp(16px, 4.16vw, 160px);
  row-gap: clamp(24px, 3.33vw, 120px);
}

.container {
  gap: 1.5rem;
  z-index: 1;
}

.logofooterIcon {
  width: 13.5rem;
  position: relative;
  height: 1.5rem;
  object-fit: cover;
}

.tagline1 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-weight: 500;
  display: inline-block;
}

.logo {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.5rem;
  min-width: 15.625rem;
  max-width: 22.5rem;
}

.lable {
  cursor: pointer;
  align-self: stretch;
  position: relative;
  font-weight: 500;
}

.phoneicon {
  width: 1rem;
  position: relative;
  height: 1rem;
  overflow: hidden;
  flex-shrink: 0;
}

.phonenumber {
  margin: 0;
  height: 1.063rem;
  flex: 1;
  position: relative;
  text-decoration: underline;
  display: inline-block;
}

.detailswrapper,
.mobile,
.support {
  display: flex;
  justify-content: flex-start;
}

.mobile {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.detailswrapper,
.support {
  flex-direction: column;
  align-items: flex-start;
}

.detailswrapper {
  align-self: stretch;
  gap: 0.25rem;
  font-size: clamp(12px, 0.72vw, 28px);
}

.support {
  flex: 1;
  gap: 0.5rem;
  min-width: 15rem;
  max-width: 19.375rem;
}

.lalBungalowAdd {
  display: block;
}

.address {
  margin: 0;
  flex: 1;
  position: relative;
  display: inline-block;
}

.addresscontainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
  font-size: clamp(12px, 0.72vw, 28px);
}

.contactdetails {
  font-size: 0.875rem;
}

.contactdetails {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 5rem;
  column-gap: clamp(16px, 4.16vw, 160px);
  row-gap: clamp(16px, 4.16vw, 160px);
}

.container1 {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 5rem;
  column-gap: clamp(16px, 4.16vw, 160px);
  row-gap: clamp(16px, 4.16vw, 160px);
}

.footer,
.mainpage {
  align-self: stretch;
  display: flex;
  font-family: Lato;
}

.footer {
  background-color: #fff;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 1rem 0 1rem 0;
  z-index: 2;
  text-align: left;
  font-size: clamp(10px, 0.83vw, 32px);
  color: #000;
}

.mainpage {
  background-color: #faf4f0;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: 10rem;
  text-align: center;
  font-size: clamp(24px, 2.5vw, 80px);
  color: #171c26;
}

.mobileHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 1rem 3rem;
  position: relative;
  z-index: 1;
}

.mobileHeader .helpBtn {
  text-transform: capitalize !important;
  border-radius: 4px !important;
  background: #cddc57 !important;
  min-width: inherit !important;
  padding: 3px 16px !important;
  color: #fff !important;
  font-family: "Lato", sans-serif !important;
  font-size: clamp(12px, 1.5vw, 14px) !important;
  font-weight: 500 !important;
}

.mobileHeader .headerWebLogo {
  display: block;
  width: 280px;
}

.mobileHeader .headerMobileLogo {
  display: none;
}

.mobileHeader .headerText {
  color: #000;
  font-family: "IBM Plex Sans", sans-serif;
  font-size: clamp(14px, 0.93vw, 18px);
  font-weight: 500;
}

@media (max-width: 500px) {
  .tagline {
    display: none;
  }

  .mobileHeader {
    padding: 1rem !important;
  }

  .mobileHeader .headerText {
    display: none;
  }

  .mobileHeader .headerWebLogo {
    display: none;
  }

  .mobileHeader .headerMobileLogo {
    display: block;
  }

  .footer {
    box-shadow: 0px 3px 3px 1px #323232bf;
  }

  .footer .container1 {
    width: 90%;
    margin: 0 auto;
  }

  .support .lable {
    display: none !important;
  }
}
