.otpimageIcon {
  width: 14.625rem;
  height: 14.425rem;
  z-index: 0;
}

.description,
.heading {
  margin: 0;
  position: relative;
}

.heading {
  font-size: inherit;
  line-height: 100%;
  font-weight: 400;
  font-family: inherit;
}

.description {
  align-self: stretch;
  font-size: 0.875rem;
  line-height: 140%;
  color: #555454;
  text-align: center;
}

.textwrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 0.75rem;
  z-index: 1;
}

.button,
.input {
  background-color: #fff;
  width: 3rem;
  position: relative;
  border-radius: 4px;
  box-sizing: border-box;
  height: 3rem;
  overflow: hidden;
  flex-shrink: 0;
}

.button {
  cursor: pointer;
  border: 2px solid #cddc57;
  padding: 0;
}

.input {
  border: 1px solid #636363;
  outline: 0;
}

.pinfields {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.5rem;
}

.otphint,
.timer {
  margin: 0;
  line-height: 100%;
}

.otphint {
  position: relative;
}

.timer {
  font-weight: 500;
}

.resendbutton,
.timer {
  position: relative;
}

.options,
.otpdetailscontainer,
.timerresend {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.timerresend {
  flex-direction: row;
  gap: 0.25rem;
}

.timerresend p {
  font-size: clamp(13px, 1vw, 15px) !important;
}

.timerresend .resendbutton {
  text-transform: capitalize !important;
  color: #FF9A29 !important;
  font-family: 'Lato', sans-serif !important;
  font-size: clamp(14px, 1.5vw, 16px) !important;
  font-weight: 400 !important;
  line-height: 100%;
}

.options,
.otpdetailscontainer {
  flex-direction: column;
}

.options {
  align-self: stretch;
  gap: 0.75rem;
}

.otpdetailscontainer {
  gap: 1rem;
  z-index: 2;
  font-size: 1rem;
}

.submit {
  margin: 0;
  position: relative;
  font-size: 1rem;
  line-height: 100%;
  font-family: Lato;
  color: #fff;
  text-align: center;
}

.submitbutton {
  cursor: pointer;
  border: 0;
  padding: 0.75rem 7.5rem;
  background-color: transparent;
  border-radius: 8px;
  background: linear-gradient(90deg,
      #cddc57 0.01%,
      #f19d5e 36.5%,
      #c53476 69.5%,
      #ae3a7a);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.closeicon {
  width: 1.6rem;
  position: relative;
  height: 1.6rem;
  overflow: hidden;
  flex-shrink: 0;
}

.closeiconWrapper {
  cursor: pointer;
  border: 0.8px solid #000;
  padding: 0.2rem;
  background-color: #fff;
  margin: 0 !important;
  position: absolute;
  top: 1rem;
  right: 1.031rem;
  border-radius: 3.2px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
  z-index: 4;
}

.otpverification {
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3rem;
  box-sizing: border-box;
  position: relative;
  gap: 2rem;
  text-align: left;
  font-size: 1.5rem;
  color: #000;
  font-family: Lato;
}

.otpverificationDrawer {
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 1.8rem;
  box-sizing: border-box;
  position: relative;
  gap: 1rem;
  text-align: left;
  color: #000;
  font-family: Lato;
}

.drawerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.drawerHeader h2 {
  color: #000;
  font-family: 'Lato', sans-serif;
  font-size: clamp(16px, 2vw, 18px);
  font-weight: 500;
  line-height: 100%;
}

.otpverificationDrawer .drawerHeader p {
  color: #555454;
  text-align: left;
  font-family: 'Lato', sans-serif;
  font-size: clamp(13px, 1vw, 14px);
  font-weight: 400;
  line-height: 140%;
}

.closeBtn {
  cursor: pointer;
  border: 0.8px solid #000;
  padding: 0.2rem;
  background-color: #fff;
  margin: 0 !important;
  border-radius: 3.2px;

}

.drawersubmitbutton {
  cursor: pointer;
  border: 0;
  padding: 0.75rem 7.5rem;
  background-color: transparent;
  border-radius: 8px;
  background: linear-gradient(90deg,
      #cddc57 0.01%,
      #f19d5e 36.5%,
      #c53476 69.5%,
      #ae3a7a);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 3;
  width: 100%;
  margin-top: 0.5rem;
}