@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

#patientReports {
  padding-top: 30px;
  padding-inline: 1rem;
  height: calc(100vh - 200px) !important;
  overflow-y: auto;

  .headingBlock {
    text-align: center;
    margin-bottom: 20px;

    h1 {
      background: linear-gradient(90deg, #ffa63e 0%, #b03d79 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: "Lato", sans-serif !important;
      font-size: clamp(24px, 2.5vw, 48px) !important;
      font-weight: 400;
      line-height: 100%;
      margin-bottom: 10px;
    }

    p {
      color: #000;
      text-align: center;
      font-size: clamp(12px, 0.72vw, 14px);
      font-weight: 400;
      line-height: 140%;
      .break {
        display: block;
        @media (max-width: 500px) {
          display: none !important;
        }
      }
    }
  }

  form {
    text-align: center;
    max-width: 350px !important;
    margin: 0 auto;
    position: relative;

    .lineShapeLeft {
      z-index: -1;
      position: absolute;
      top: 18px !important;
      left: -65px;
      @media (max-width: 500px) {
        display: none !important;
      }
    }

    .lineShapeRight {
      z-index: -1;
      position: absolute;
      top: 18px !important;
      right: -65px;
      @media (max-width: 500px) {
        display: none !important;
      }
    }

    .formItem {
      .MuiFormControl-root {
        background: #fff;
        overflow: hidden;
      }

      div {
        border: none;
        border-radius: 8px !important;
      }

      input {
        border-radius: 8px !important;
        display: flex;
        width: 350px;
        padding: 8px 10px;
        align-items: center;
        gap: 10px;

        &:focus {
          border: 1px solid #636363;
        }
      }
    }
  }
  .buttonsGrp {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 15px !important;
  }

  .submitButton {
    padding: 6px 10px;
    &:after {
      content: "";
      position: absolute;
    }
  }

  .listItemGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 1rem;
    margin-top: 1rem;
    padding-bottom: 2rem !important;
width: 85%;
margin: 0 auto;
    .listItem {
      width: 400px;
      margin-top: 20px;
      align-self: stretch;
     @media (min-width:1920px) {
      width: 550px !important;
     }
    }
  }
  @media (max-width: 1180px) {
    height: calc(100vh - 300px) !important;
  }

  @media (max-width: 768px) {
    height: calc(100vh - 280px) !important;
    .listItemGroup {
      margin-top: 2rem;
    }
    .buttonsGrp {
      justify-content: flex-end;
    }
  }
}

.patient-reports-page {
  background: #faf4ef !important;
  min-height: 100vh;
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  font-family: "Lato", sans-serif;

  .shape {
    position: absolute;
    top: 0;
    right: 0 !important;
    bottom: 0;
    height: 100vh;
    z-index: -1;

    img {
      max-width: 300px;
      height: 100%;
      object-fit: contain;
    }
    @media (max-width: 500px) {
      display: none !important;
    }
  }
}

#verificationOTPContainer {
  .image {
    img {
      max-width: 200px;
    }
  }

  .dailogHeader {
    text-align: center;

    h2 {
      color: #000;
      font-family: Lato;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      margin-bottom: 10px;
    }

    p {
      color: #555454;
      text-align: center;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
  }
  .drawerHeader {
    text-align: left;
  }

  .otpInputContainer {
    div:not(:last-child) {
      margin-right: 24px;
    }

    input {
      width: 48px !important;
      height: 48px;
      border-radius: 4px;
      border: 1px solid #636363;
      background: #fff;

      &:focus-visible,
      &:focus {
        outline: 1px solid #cddc57;
        border: 1px solid #cddc57;
      }
    }

    .otpErrorMessage {
      font-size: 14px;
      color: red;
      text-align: center;
      padding-top: 10px;
      font-weight: 600;
    }
  }
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
