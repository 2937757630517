#uploadFile {
  margin-top: 3rem;

  .upload-block {
    display: flex;
    justify-content: center;
    background: #f5f5f5;
    padding: 0;

    .upload-card {
      background: #fff;
      border-radius: 2rem;
      max-width: 400px;
      padding: 40px 60px 30px 60px;
      box-shadow: none;

      h5 {
        margin-bottom: 3rem;
        text-align: center;
        font-weight: 600;
        color: #333;
        font-size: 1.5em;
      }


      .form-file-upload {
        height: 16rem;
        width: 28rem;
        max-width: 100%;
        text-align: center;
        position: relative;
        cursor: pointer !important;

        .input-file {
          display: none;
        }

        #label-file-upload {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          border-width: 2px;
          border-radius: 1rem;
          border-style: dashed;
          border-color: #4996d2;
          background: rgb(2, 0, 36);
          background: linear-gradient(to top, rgba(2, 0, 36, 1) 0%, rgba(255, 255, 255, 0.1) 0%, rgba(0, 212, 255, 0.1) 2%);

          .upload-button {
            cursor: pointer;
            padding: 0.25rem;
            font-size: 1rem;
            border: none;
            font-family: "Oswald", sans-serif;
            background-color: transparent;
          }

          .upload-button:hover {
            text-decoration-line: underline;
          }
        }

        #label-file-upload.drag-active {
          background-color: #ffffff;
        }

        .input-file-upload {
          display: none;
        }

        .drag-file-element {
          position: absolute;
          width: 100%;
          height: 100%;
          border-radius: 1rem;
          top: 0px;
          right: 0px;
          bottom: 0px;
          left: 0px;
        }
      }

      .buttons {
        display: flex;
        justify-content: center;

        .cancel-btn {
          background-color: #ff9891;
          padding: 7px 20px !important;
          color: #ffffff;
          font-weight: 600;
          border-radius: 7px;
        }

        .submit-btn {
          padding: 7px 20px !important;
          background-color: #3F51B5;
          color: #ffffff;
          font-weight: 600;
          border-radius: 7px;
        }
      }
    }

    .uploadText {
      img {
        max-width: 60px;
        margin-bottom: 20px;
      }
    }
  }

  .backButton {
    max-width: 500px;
    display: flex;
    justify-content: flex-end;
    margin: 20px auto;

    button {
      border-radius: 5px;
      font-size: 1em;
      padding: 5px 10px !important;
      font-weight: 600;
      text-transform: uppercase;
      background: #4996d2;
      color: #fff !important;


      &:hover {
        background: #4996d2;
      }

      svg {
        width: 18px;
        height: 18px;
        padding: 3px;
        fill: #fff;
        color: #fff;
        margin-right: 5px;
      }

    }
  }
}


.uploadSection {}