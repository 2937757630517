#resultDetails {
  margin-top: 2em;
  background: #fff;
  max-width: 80%;

  .primaryDashboard {
    display: none;
  }

  .navbarNavigation {
    color: #00435d;
    font-weight: 500;

    a {
      color: #e91e63;
      text-decoration: underline;
    }
  }
}
