.dnadecoderlogoIcon {
  width: 22.5rem;
  position: relative;
  height: 2.5rem;
  object-fit: cover;
}

.pagename {
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 100%;
  font-weight: 500;
  font-family: inherit;
}

.pagelink,
.pagelink1 {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.625rem;
}

.pagelink1 {
  color: #4a4a4a;
}

.userIcon {
  width: 2.5rem;
  position: relative;
  border-radius: 50%;
  height: 2.5rem;
  object-fit: cover;
}

.container,
.navbar,
.navlinks {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.navlinks {
  justify-content: flex-start;
  gap: 1.5rem;
}

.container,
.navbar {
  align-self: stretch;
  justify-content: space-between;
}

.navbar {
  margin: 0;
  background-color: #fff;
  border-bottom: 1px solid #d0d0d0;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.25rem 5rem;
  text-align: left;
  font-size: 0.875rem;
  color: #b03877;
  font-family: Poppins;
}

.text {
  margin: 0;
  color: #000;
  font-family: "Lato", sans-serif;
  font-size: clamp(13px, 0.83vw, 18px);
  font-weight: 400;
}

.back {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}
.back button {
  background-color: #fff !important;
  min-width: inherit !important;
  border-radius: 4px !important;
  padding: 4px !important;
}

.stepSymbolIcon {
  width: 2rem;
  position: relative;
  height: 2rem;
}

.summary {
  margin: 0;
  position: relative;
  font-weight: 500;
}

.stepicontext {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: #27ae60;
}

.steptrail {
  width: 5rem;
  position: relative;
}

.stepicontext1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.actions,
.header,
.stepper {
  display: flex;
  flex-direction: row;
}

.stepper {
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 0.813rem;
  color: #465668;
  font-family: Inter;
  width: 60%;
}

.actions,
.header {
  align-items: center;
}

.actions {
  justify-content: flex-end;
  gap: 0.875rem;
}

.header {
  align-self: stretch;
  justify-content: space-between;
  text-align: center;
  font-size: 1rem;
  color: #000;
  font-family: Lato;
}

.primaryreportstab {
  align-self: stretch;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.05);
}

.sideTabbar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1.437rem;
}

.heading {
  color: #000;
  font-family: "Inter", sans-serif;
  font-size: clamp(13px, 0.833vw, 18px);
  font-weight: 600;
  line-height: 140%;
  text-transform: uppercase;
}

.patientheader {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: 1rem;
  color: #000;
  font-family: Inter;
}

.lable {
  font-family: "Poppins", sans-serif;
  line-height: 140%;
  font-weight: 500;
  font-size: clamp(11.5px, 0.72vw, 16px);
}

.name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
  width: 18.4%;
}

.gender {
  margin: 0;
  color: rgba(17, 17, 19, 0.6);
  font-family: "Poppins", sans-serif;
  font-size: clamp(12px, 0.625vw, 14px);
  font-weight: 500;
  line-height: 140%;
}

.patientinformation,
.wrap {
  align-self: stretch;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.wrap {
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 1.2rem;
  column-gap: 2%;
}

.patientinformation {
  border-radius: 16px;
  background-color: #fff;
  border: 1px solid #cacaca;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
}

.input {
  border: 0;
  background-color: transparent;
  align-self: stretch;
}

.input3,
.select {
  font-family: Poppins;
  font-size: 1rem;
  color: rgba(17, 17, 19, 0.6);
}

.select {
  align-self: stretch;
}

.input3 {
  border: 0;
  background-color: transparent;
}

.datepicker,
.input3 {
  align-self: stretch;
}

.patientinformation1,
.visit {
  display: flex;
  flex-direction: column;
}

.visit {
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
  min-width: 12.5rem;
  max-width: 14.375rem;
}

.patientinformation1 {
  align-self: stretch;
  border-radius: 16px;
  background-color: #fff;
  border: 1px solid #cacaca;
  align-items: flex-end;
  justify-content: center;
  padding: 1.5rem;
  gap: 1rem;
}

.heading2 {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 140%;
  text-transform: uppercase;
  font-weight: 600;
  font-family: inherit;
}

.input5 {
  align-self: stretch;
  font-family: Arial;
  font-size: 0.813rem;
  color: #333;
}

.hyperophicdialatedCardiomyop,
.summary1 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.summary1 {
  font-size: 1.125rem;
  /* color: #f35a3e; */
  font-family: Inter;
}

.hyperophicdialatedCardiomyop {
  border-radius: 24px;
}

.cancelbutton {
  align-self: stretch;
  width: 3.25rem;
  position: relative;
}

.buttonsgroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1.75rem;
}

.detailscontainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  column-gap: 1.5rem;
  row-gap: 1rem;
}

.patienticon {
  width: 15px;
  height: 15px;
}

.heading3 {
  margin: 0;
  color: #c53476;
  font-family: "Poppins";
  font-size: clamp(12px, 0.72vw, 16px);
  font-weight: 500;
  line-height: 100%;
}

.header2 {
  align-self: stretch;
  border-bottom: 1px solid #dadada;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 0.5rem;
  gap: 0.5rem;
  text-align: left;
  font-size: 0.875rem;
  color: #c53476;
  font-family: Poppins;
}

.details1 {
  margin: 0;
  width: 10.875rem;
  flex: 1;
  position: relative;
  font-size: 1rem;
  line-height: 140%;
  color: rgba(17, 17, 19, 0.6);
  display: inline-block;
}

.wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.25rem;
}

.details2 {
  margin: 0;
  color: rgba(17, 17, 19, 0.6);
  font-family: "Poppins", sans-serif;
  font-size: clamp(12px, 0.625vw, 14px);
  font-weight: 500;
  line-height: 140%;
}

.details {
  align-self: stretch;
  flex-direction: column;
  gap: 0.8rem;
}

.addsumarypage,
.bodycontainer,
.details,
.group,
.patientInformation {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.patientInformation {
  width: 12%;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.09);
  border-radius: 8px;
  background-color: #fff;
  flex-direction: column;
  padding: 1rem;
  box-sizing: border-box;
  gap: 0.5rem;
}

.addsumarypage,
.bodycontainer,
.group {
  align-self: stretch;
  flex-direction: row;
  gap: 1.5rem;
  column-gap: 3rem !important;
}

.addsumarypage,
.bodycontainer {
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 0 2rem;
  gap: 2rem;
}

.addsumarypage {
  background-color: #faf4f0;
  overflow: hidden;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0 0;
  gap: 1.5rem;
  text-align: left;
  color: #000;
  font-family: Poppins;
  width: 95%;
  margin: 0 auto;
}
