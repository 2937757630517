.addSummeryIcon {
  height: 25px;
  width: 25px;
}

.tableContainer {
  padding: 0;
  width: 93%;
  margin: 0 auto;
  border-radius: 2rem;
}

.tableContainer .summeryNewButton {
  font-size: 12px;
  font-family: "Poppins", sans-serif;
  gap: 5px;
  padding: 5px;
  text-transform: capitalize;
  background: #a3be3c;
  box-shadow: none;
}

.tableContainer .summeryNewButton:hover {
  background: #a3be3c;
}

.deleteDialog {
  max-width: 500px;
  margin: 0 auto;
}

.canceldeletePopup .confirmDelete {
  font-family: "Poppins", sans-serif;
  font-size: clamp(22px, 1.14vw, 24px);
  letter-spacing: 0px;
  font-weight: 400;
}

.canceldeletePopup .deleteButton {
  background: #ed5e68;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

.canceldeletePopup .deleteButton:hover {
  background: #ed5e68;
}

.canceldeletePopup {
  padding: 5px;
}

.canceldeletePopup .cancelButton {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  background: #fff;
  color: #ed5e68;
  border: 1px solid #ed5e68;
  gap: 3px;
}

.canceldeletePopup .cancelButton:hover {
  background: #fff;
  color: #ed5e68;
  border: 1px solid #ed5e68;
}

.canceldeletePopup .sureyouwanttoDelete {
  font-family: "Poppins", sans-serif;
  font-size: clamp(14px, 0.72vw, 18px);
  font-weight: 400;
}

.deleteDialog .title {
  display: flex;
  align-items: center;
  column-gap: var(--gap-7xs);
}

.summaryPageHeader {
  display: flex;
  justify-content: space-between;
  padding: 0.3rem 0 0.5rem 0;
}