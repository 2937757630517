.custm-input .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 2px 0px 2px 18px !important;
}

.standard-form {
  width: 90%;
  margin: 0 auto;
}
.standard-form input {
  padding: 0px 10px 0 10px;
  height: 38px;
  max-width: calc(100% - 20px);
}
.standard-form .MuiFormControl-root,
.standard-form .MuiTextField-root {
  max-width: 100%;
  display: block;
  width: 100%;
}
.standard-form .MuiInputBase-root {
  max-width: 100%;
  display: block;
}
.standard-form .custm-input {
  max-width: 100%;
  flex-basis: 100%;
}
.standard-form .MuiGrid-root,
.standard-form .MuiTypography-root {
  font-size: 14px;
  max-width: 100%;
  padding-bottom: 3px;
  line-height: 1.4;
  flex-basis: 100%;
}
.standard-form .d-block {
  display: block;
  padding-bottom: 40px;
}
.block-inner
{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.innergrid
{
  display: flex;
  flex-direction: row;

}
