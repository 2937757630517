.tablePagination {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    color: #000000 !important;
    border-radius: 0 !important;
    width: 100% !important;
}

.tablePagination * {
    font-size: clamp(0.8rem, 0.7vw, 1rem);
}

.label {
    margin-right: 10px !important;
        font-family: "Poppins", sans-serif !important;

}
.tablePagenationBlock {
    padding: 0.625rem 0.625rem 0.625rem 0.9375rem !important;
    box-shadow: none !important;
    border: 1px solid #efefef !important;
    font-family: "Poppins", sans-serif !important;
}