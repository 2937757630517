#resultFiles {
  margin: 3em 0;
  height: 100vh;
  width: 100%;
  background-color: #f1f5f7;

  .buttons {
    display: flex;
    justify-content: space-around;

    .back-btn {
      margin-bottom: 10px;
      padding: 6px 10px !important;
      font-size: 13px;
    }

    .upload-btn {
      float: right;
      overflow: hidden;
      margin-bottom: 10px;
      border-radius: 4px !important;
      padding: 10px 15px !important;
      background: #0061ec !important;
      min-height: auto;
      line-height: 1;
      font-size: 13px;
      color: #fff;
      font-weight: 600;
    }
  }

  .table-data {
    display: flex;
    justify-content: center;

    .table-header {
      background: aliceblue;
      height: 100%;
      width: 70%;

      .custm-th {
        color: #6a6a6a;
        font-weight: 600;
        background: #faf9ff !important;
        border-bottom: 1px solid #c0c4dc !important;
        padding: 0.5em 0 0.5em 15px !important;
      }

      .table-head {
        .table-row {
          background: #fbfbfb;
        }
      }

      .data {
        color: #606266;
        padding: 8px 0 0 15px !important;
      }

      .table-group {
        background: #ffffff;
      }

      .table-item:hover {
        cursor: pointer;
        background-color: #f5f7fa;
      }

      .pagination {
        background: #fff;
        border-radius: 0 0 10px 10px;
      }
    }
  }
}

.MuiButton-containedPrimary {
  min-width: 64px;
  padding: 4px 6px;
  font-size: 12px;
}

.resultFilesContainer {
  width: 91%;
  margin: 0 auto;

  .statusChip {
    font-size: clamp(12px, 0.72vw, 16px) !important;
    font-family: "Poppins", sans-serif !important;
    font-weight: 500 !important;
    border: none !important;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.05);
    padding: 0 !important;
    height: 28px !important;
  }

  .searchFieldsGrp {
    display: flex;
    align-items: center;
    gap: 1rem;

    input {
      font-size: clamp(0.75em, 0.72vw, 1em);
      font-family: "Poppins", sans-serif;
    }

    .barcodeSearch {
      input {
        height: 26px;
        padding: 5px 15px;
        color: #000000;
        font-size: clamp(0.75em, 0.72vw, 1em);
        font-family: "Poppins", sans-serif;
        background-color: #fff;
        border-radius: 6px;
        width: 200px;
      }

      fieldset {
        border: 1px solid #e3ddd9;
        border-radius: 6px;
      }
    }

    .patientSearch {
      input {
        height: 26px;
        padding: 5px 15px;
        color: #000000;
        font-size: clamp(0.75em, 0.72vw, 1em);
        font-family: "Poppins", sans-serif;
        background-color: #fff;
        border-radius: 6px;
        width: 260px;
      }

      fieldset {
        border: 1px solid #e3ddd9;
        border-radius: 6px;
      }
    }
  }
  .selectCategory {
    width: 200px;
    .MuiInputBase-root {
      background: #fff;
    }
    .MuiSelect-select {
      padding: 6.5px 14px;
      font-size: clamp(0.8em, 0.72vw, 1.2em);
      font-family: "Poppins", sans-serif;
    }

    fieldset {
      border: 1px solid #fff;
      border-radius: 4px;
    }
  }

  .searchFilterNavbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;

    .leftItem {
      .select-container {
        margin: 0;
        min-width: 200px;
        border-radius: 8px;
      }

      fieldset {
        border-radius: 8px;
      }

      .select {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 8px;
        font-size: 14px;
        width: 100%;
      }

      .select:focus {
        outline: none;
        border-color: #007bff;
        box-shadow: 0 0 0;
      }
    }

    .rightItem {
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        li {
          &:not(:last-child) {
            margin-right: 0.5em;
          }

          #dropdown-menu {
            a {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  table {
    .statusActionButton {
      padding: 5px 0 !important;
      min-width: inherit !important;
      border-radius: 30px !important;
      box-shadow: none;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      width: 80px;
      text-align: center;
      font-size: clamp(11px, 0.625vw, 13px) !important;
      line-height: 100%;

      @media (min-width: 1920px) {
        width: 90px !important;
      }
    }

    .generateFileBtn,
    .viewProcessBtn,
    .processFileBtn {
      text-transform: capitalize;
      padding: 6px 0;
      min-width: inherit;
      border-radius: 8px;
      box-shadow: none;
      font-size: clamp(11px, 0.625vw, 13px) !important;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      width: 110px;
      line-height: 100%;
    }

    .generateFileBtn {
      background: linear-gradient(90deg,
          #cddc57 0.01%,
          #f19d5e 36.5%,
          #c53476 69.5%,
          #ae3a7a 100%);
      color: #fff;

      &:hover {
        background: linear-gradient(90deg,
            #cddc57 0.01%,
            #f19d5e 36.5%,
            #c53476 69.5%,
            #ae3a7a 100%);
        color: #fff;
      }
    }

    .processFileBtn {
      background: linear-gradient(90deg, #d9d9d9 0%, #b6b6b6 53%, #e5e5e5 100%),
        linear-gradient(90deg,
          #cddc57 0.01%,
          #f19d5e 36.5%,
          #c53476 69.5%,
          #ae3a7a 100%);
      color: #000;

      &:hover {
        background: linear-gradient(90deg,
            #d9d9d9 0%,
            #b6b6b6 53%,
            #e5e5e5 100%),
          linear-gradient(90deg,
            #cddc57 0.01%,
            #f19d5e 36.5%,
            #c53476 69.5%,
            #ae3a7a 100%);
        color: #000;
      }
    }

    .viewProcessBtn {
      background: linear-gradient(90deg,
          #cddc57 0.01%,
          #f19d5e 36.5%,
          #c53476 69.5%,
          #ae3a7a 100%);
      padding: 1px 1px !important;
      box-sizing: border-box;
      cursor: pointer;

      .text {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-radius: 8px;
        background-color: #fff;
        line-height: 100%;

        .gradientText {
          background: linear-gradient(90deg,
              #cddc57 0.01%,
              #f19d5e 36.5%,
              #c53476 69.5%,
              #ae3a7a 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          padding-block: 6px;
          box-sizing: border-box;
          line-height: 100%;
        }
      }
    }

    .finaliseBtn {
      width: 80px !important;
    }
  }
}

.reportViewNavbar {
  margin: 0 auto;
  position: fixed;
  top: 65px;
  left: 0;
  right: 0;
  z-index: 2;
  background: whitesmoke;

  .inside {
    width: 900px;
    margin: 0 auto;
    padding: 10px 0;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    max-width: 900px;

    li {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }

    button {
      padding: 5px 15px;
      font-weight: 600;
      color: #ffffff;

      svg {
        margin-right: 5px;
        width: 15px;
      }

      &.print-btn {
        background: #08b4b9;

        &:hover {
          background: #08b4b9;
        }
      }

      &.edit-button {
        background: #f94e5e;

        &:hover {
          background: #f94e5e;
        }
      }

      &.finalize-button {
        background: #368110;

        &:hover {
          background: #368110;
        }
      }

      &.finalize-disable {
        background: #333333;
        color: #ffffff;

        &:hover {
          background: #333333;
          color: #ffffff;
          pointer-events: auto !important;
          cursor: not-allowed !important;
        }
      }

      &.download-btn {
        background: #607d8b;

        &:hover {
          background: #607d8b;
        }
      }

      &.back-button {
        background: #2f80ed;

        &:hover {
          background: #2f80ed;
        }
      }
    }
  }
}