.maincontainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 2.5rem;
  width:95%;
  margin:0 auto;
}
.successText{
  
    font-size: clamp(26px,1.35vw,28px);
    color: #009821;
    font-weight:500;
    font-family: "IBM Plex Sans", sans-serif;
 
}
.summarySuccessCard .successbackButton{
  border-radius: 6px;
  border: 2px solid #FF583E;
  color: #FF583E;
font-family: "IBM Plex Sans", sans-serif;
font-size: clamp(14px, 0.72vw, 18px);
font-weight: 500;
width:40%;
}
.summarySuccessCard .successbackButton:hover{
  border-radius: 6px;
  border: 2px solid #FF583E;
  color: #FF583E;
}
.summarySuccessCard .successDownloadButton{
  border-radius: 6px;
background: #069;
color: #fff;
font-weight: 500;
font-family: "IBM Plex Sans", sans-serif;
width:40%;
}
.summarySuccessCard .successDownloadButton:hover{
  border-radius: 6px;
  background: #069;
  color: #fff;
}
.summarySuccessCard{
  
    background: #e8ffed;
    min-height: 18rem;
    min-width: 30rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
   
  
}
