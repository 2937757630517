.body {
    font-size: 15px;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    font-family: "Roboto", sans-serif;
    margin: 0;
    padding: 0;
    height: 100%;
    position: relative;
    z-index: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    font-weight: 500;
}

p {
    margin: 0;
}

@page {
    size: A4;
    margin: 25px;
    border-radius: 10px;
}

.defaultReportPage {
    width: 900px;
    margin: 0 auto !important;
    position: relative;
    overflow: hidden;
    border: none;
    background: #ffffff;
}

@media print {
    #defaultReportPage {
        background: transparent;
    }
}

.img-fluid {
    max-width: 100%;
}

.heading3 {
    margin: 0;
    flex: 1;
    position: relative;
    font-size: inherit;
    line-height: 100%;
    font-weight: 600;
    /* font-family: inherit; */
}

.header {
    align-self: stretch;
    border: 1px solid #24150e;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 1.25rem 1.75rem 1rem;
    text-align: left;
    font-size: 1.25rem;
    color: #24160e;
    /* font-family: Poppins; */
}

.bodyheadingcontainer,
.bodyheadingcontainer:hover {
    align-self: stretch;
    width: auto;
    height: auto;
    flex-direction: row;
    align-items: flex-start;
}

.bodyheadingcontainer {
    display: flex;
    justify-content: flex-start;
    padding: 0.75rem 0;
    box-sizing: border-box;
    gap: 0.625rem;
}

.bodyheadingcontainer:hover {
    gap: 10px;
    padding: 12px 0;
}

.data {
    margin: 0;
    flex: 1;
    position: relative;
    font-size: 14px;
    line-height: 140%;
    font-weight: 600;
    /* font-family: Poppins; */
    color: #9b2428;
    text-align: left;
}

.bodyheadingcontainer:hover,
.cell,
.headingcell {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
}

.cell,
.headingcell {
    overflow: hidden;
}

.cell {
    align-self: stretch;
    background-color: #fff;
    height: 1.313rem;
    flex-shrink: 0;
    flex-direction: row;
    align-items: center;
    padding: 0.125rem 0.625rem;
}

.headingcell {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.375rem 0;
    max-width: 6.25rem;
    width: 100%;
    height: 100%;
}

.data1,
.td28 {
    position: relative;
}

.td28 {
    border-bottom: 1px solid #000;
    padding-right: 0;
    padding-bottom: 0;
}

.data1 {
    margin: 0;
    flex: 1;
    font-size: 14px;
    line-height: 140%;
    font-weight: 500;
    /* font-family: Poppins; */
    color: #282828;
    text-align: left;
}

.cell1,
.cellcontainer {
    align-self: stretch;
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
}

.cell1 {
    background-color: #fff;
    flex-direction: row;
    align-items: center;
    padding: 0.125rem 0.625rem;
}

.cellcontainer {
    flex: 1;
    border-right: 1px solid #000;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.375rem 0;
}

.data3 {
    margin: 0;
    flex: 1;
    position: relative;
    font-size: 14px;
    line-height: 140%;
    font-weight: 500;
    /* font-family: Poppins; */
    color: #171717;
    text-align: left;
}

.cellcontainer1 {
    align-self: stretch;
    flex: 1;
    border-right: 1px solid #000;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.375rem 0;
    gap: 0.125rem;
}

.data5 {
    margin: 0;
    flex: 1;
    position: relative;
    font-size: 14px;
    line-height: 140%;
    font-weight: 500;
    /* font-family: Poppins; */
    color: #fff;
    text-align: left;
}

.bodycell,
.cell5,
.detailsgroup,
.detailsgroup1 {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
}

.cell5 {
    align-self: stretch;
    background-color: #9b2428;
    flex-direction: row;
    align-items: center;
    padding: 0.125rem 0.625rem;
}

.bodycell,
.detailsgroup,
.detailsgroup1 {
    align-items: flex-start;
}

.detailsgroup,
.detailsgroup1 {
    align-self: stretch;
    border-bottom: 1px solid #000;
    flex-direction: row;
}

.bodycell {
    border-left: 1px solid #000;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.data62,
.td29 {
    position: relative;
}

.td29 {
    padding-bottom: 0;
}

.data62 {
    margin: 0;
    flex: 1;
    font-size: 14px;
    line-height: 140%;
    font-weight: 500;
    /* font-family: Poppins; */
    color: #9b2428;
    text-align: left;
}

.td30 {
    padding-right: 0;
}

.td30,
.td31 {
    position: relative;
}

.details {
    align-self: stretch;
    border: 1px solid #000;
    overflow: hidden;
}

.datatable,
.tablebody {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.tablebody {
    padding: 0 1.75rem 2.5rem;
}

.datatable {
    border: 1px solid #000;
}

.logo-icon2 {
    width: 11.819rem;
    height: 1.131rem;
}

.footer2 {
    align-self: stretch;
    background: linear-gradient(90deg, #d2d75f, #e48f6b 41.76%, #ae3a7a);
    height: 2.125rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 0 1.25rem;
    box-sizing: border-box;
}

.container2,
.page1 {
    display: flex;
    align-items: flex-start;
}

.container2 {
    align-self: stretch;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
}

.page1 {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: auto;
    overflow: hidden;
    flex-direction: row;
    justify-content: flex-start;
    box-sizing: border-box;
    text-align: left;
    font-size: 1.25rem;
    color: #24160e;
    /* font-family: Poppins; */
}

.table {
    width: 100%;
}

#Pharmacogenomics {
    margin-top: 40px;
}

#Pharmacogenomics table {
    border: 1px solid #24150e;
    width: 100%;
    border-collapse: collapse;
}

#Pharmacogenomics table {
    page-break-inside: auto;
}

#Pharmacogenomics tr {
    page-break-after: auto;
}

#Pharmacogenomics tr table tr {
    page-break-inside: auto;
}

#Pharmacogenomics table thead {
    width: 100%;
}

#Pharmacogenomics table thead tr th {
    padding: 15px 0;
    font-size: 2rem;
    font-weight: 600;
    text-align: left;
    border-bottom: 1px solid #24150e;
    border-left: 1px solid #24150e;
    border-right: 1px solid #24150e;
    padding-left: 50px;
}

#Pharmacogenomics table thead .heading th {
    font-size: 1.8rem;
    font-weight: 600;
    color: #9b2428;
    text-align: center;
}

#Pharmacogenomics table thead tr:first-child th {
    border-top: 1px solid #24150e;
}

#Pharmacogenomics table tbody {
    border: 1px solid #000;
}

#Pharmacogenomics table tbody tr td {
    padding: 0.4rem 0.825rem;
    vertical-align: top;
    font-size: 14px;
    line-height: 20px;
}

#Pharmacogenomics table tbody tr td:not(:last-child) {
    border-right: 1px solid #000;
}

#Pharmacogenomics table tbody tr td {
    border-top: none;
}

#Pharmacogenomics table tbody tr {
    border-bottom: 1px solid #24150e;
}

#Pharmacogenomics table tbody tr .details {
    display: flex;
    align-items: center;
    column-gap: 1rem;

    ul {
        li {
            list-style-type: none;
            padding: 5px 0;

            p {
                margin: 0;
            }
        }
    }
}

#Pharmacogenomics table tbody tr .sub-heading-one {
    color: #000;
    padding: 20px;
    font-size: 16px;
    font-weight: 600;
}

#Pharmacogenomics table tbody tr .sub-heading-two {
    color: #32cd32;
    padding: 20px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

#Pharmacogenomics table tbody tr .sub-heading-three {
    color: #ff6347;
    padding: 20px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

#Pharmacogenomics table tbody tr .sub-heading-four {
    color: #8b0000;
    padding: 20px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
}

#Pharmacogenomics table tbody tr .background-one {
    background-color: #f0fff0;
}

#Pharmacogenomics table tbody tr .background-two {
    background-color: #ffe4e1;
}

#Pharmacogenomics tr td table {
    border: none;
}

#Pharmacogenomics tr:last-child td {
    border-bottom: 1px solid #000;
}

#Pharmacogenomics .whiteSpaceOne {
    position: relative;
}

#Pharmacogenomics .whiteSpaceOne::after {
    content: "";
    background: #fff;
    width: 35px;
    height: 30px;
    position: absolute;
    left: -49.5px;
    bottom: -25px;
}

#Pharmacogenomics .whiteSpaceTwo {
    position: relative;
}

#Pharmacogenomics .whiteSpaceTwo::before {
    content: "";
    background: #fff;
    width: 35px;
    height: 30px;
    position: absolute;
    right: -478px;
    bottom: -25px;
}

.emptyFooter {
    height: 57px;
    display: none;
}

.defaultFooter {
    height: 57px;
    position: relative;
    overflow: hidden;
}

.defaultHeader {
    height: 30px;
    position: relative;
    overflow: hidden;
}

.emptyHeader {
    height: 40px;
    display: none;
}

.defaultFooter .logoImage {
    width: 100%;
    height: 57px;
    object-fit: cover;
}

@media print {
    .defaultFooter {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        width: 900px;
        margin: 0 auto;
    }

    .emptyFooter,
    .emptyHeader {
        display: block;
    }

    #pageborder {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 57px;
        border: 1px solid #24150e;
    }

    #Pharmacogenomics {
        margin-top: none;
    }

    #Pharmacogenomics table thead tr th {
        border-left: none;
        border-right: none;
    }

    #Pharmacogenomics table tbody tr td table tr td:first-child {
        border-left: none;
    }

    #Pharmacogenomics table tbody tr td table tr td:last-child {
        border-right: none;
    }
}

.food-avoid-intake {
    padding: 1.5rem 0;
}

.food-avoid-intake img {
    display: table;
    margin: 0 auto;
}