.heading {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: clamp(20px, 1.04vw, 24px);

  line-height: 100%;
  font-weight: 500;
  font-family: inherit;
}
.headercontainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  text-align: left;
  font-size: 1.5rem;
  color: #ed8818;
  font-family: "IBM Plex Sans";
}
