@import "../src/components/scss/responsive";
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$primaryColor: #00435d;
$primaryBlack: #000000;
$primaryWhite: #ffffff;
$secondary-color: #f05b43;

html,
body {
  margin: 0;
  padding: 0;
  font-family: "Raleway", sans-serif;
  background: #faf4f0 !important;
}

button {
  box-shadow: none !important;
}

.react-pdf__Page__textContent {
  display: none;
}

.react-pdf__Page__annotations {
  display: none;
}

.container-fluid {
  max-width: 1250px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;

  @include media-breakpoint-down(xs) {
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.container-fluid-navbar {
  display: flex;
  justify-content: space-between;
  width: 91%;
  margin: 0 auto;
}

//INPUT FIELD DATE PICKER FONT SIZE
.rs-input-group.rs-input-group-inside .rs-input {
  font-size: clamp(11px, 0.72vw, 16px) !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 400 !important;
}

.rs-input-group-inside:focus-within,
.rs-input-group-inside-focus {
  outline: 0 !important;
  border-color: inherit !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb {
  background: #cacaca;
  border-radius: 10px;
  color: #fbdeda;
}

@import "../src/components/scss/ResultFiles.scss";
@import "../src/components/scss/UploadFile.scss";

#dashboardPage {
  margin: 2em 0;

  .pageName {
    text-align: center;
    font-size: 1.5em;
    font-weight: 500;
    margin-bottom: 0;
  }

  .blockName {
    font-size: 1.2em;
    font-weight: 500;
    color: $primaryColor;
    margin-bottom: 20px;
  }

  .customCard {
    border: 1px solid rgba($primaryColor, 0.3);
    border-radius: 6px;
    padding: 20px 10px 10px 10px;
    background: #fff;
  }

  .formControl {
    padding: 0 7px 15px 7px;

    .heading {
      font-size: 14px;
      color: #333;
      padding-bottom: 3px;
    }

    input {
      padding: 7px 15px;
      color: #000;
      font-weight: 500;

      &::placeholder {
        font-size: 13px;
      }
    }

    .text-field {
      width: 100%;
    }
  }

  .parameterInformation {
    .parameterName {
      font-size: 13px;
      margin: 0;
      padding-bottom: 5px;
      color: #000;
      font-weight: 500;
    }
  }

  .MuiFormControl-root {
    width: 100%;
  }
}

#viewProfile {
  padding: 2em 0;

  .container-fluid {
    max-width: 800px;
  }

  .backNavigation {
    ul {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-end;
      list-style: none;

      li {
        &:not(:last-child) {
          margin-right: 10px;
        }

        button {
          padding: 4px 15px;
          min-height: auto;
        }
      }
    }
  }

  .viewProfileCard {
    background: #fff;
    border: 1px solid rgba($primaryColor, 0.3);
    border-radius: 7px;

    .blockHeading {
      font-weight: 500;
      font-size: 1.1em;
      margin: 0;
      border-bottom: 1px solid rgba($primaryColor, 0.2);
      padding: 15px 20px;
    }

    .blockBody {
      padding: 20px 20px;
    }

    .formGroup {
      margin-bottom: 15px;

      p {
        margin: 0;

        &.name {
          color: #333;
          padding-bottom: 3px;
        }

        &.value {
          color: #000;
          font-weight: 500;
          text-transform: capitalize;
        }
      }
    }
  }
}

#editProfilePage {
  .editSection {
    width: 900px;
    margin: auto;
    background: #fff;
    border: 1px solid rgba(0, 67, 93, 0.3);
    border-radius: 7px;

    .editHeaderPart {
      border-bottom: 1px solid rgba(0, 67, 93, 0.2);
      padding: 15px 20px;

      .header {
        font-weight: 500;
        font-size: 1.3rem;
        margin: 0;
      }
    }

    .editBodyBlock {
      padding: 15px 20px;

      .eachFiedDetails {
        display: flex;
        flex-direction: column;

        label {
          margin-bottom: 5px;
          line-height: 22px;
          font-size: 14px;
          font-weight: 500;
          color: #606266;
        }
      }
    }

    .bottomButtonBlock {
      padding: 15px 20px;
      display: flex;
      column-gap: 1rem;
      justify-content: flex-end;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
  }
}

.dietaryPopup {
  .MuiPaper-root {
    margin: 0 auto;
    width: 600px;
    border-radius: 15px;
  }

  .popupHeader {
    padding: 10px 20px;
    border-bottom: 1px solid rgba(0, 67, 93, 0.2);

    .heading {
      font-size: 1.3rem;
      margin: 0;
      color: #f35a3e;
      font-weight: 500;
    }
  }

  .popupBody {
    padding: 0;

    .subHeading {
      font-size: 1rem;
      margin: 0;
      font-weight: 500;
      color: #fff;
      padding: 10px 20px;
      position: sticky;
      top: 0;
      z-index: 9;

      &.right {
        background-color: #a3be3c;
      }

      &.left {
        background-color: #e14146;
      }
    }

    .eachBlock {
      padding-left: 20px;
      border-right: 1px solid rgba(0, 67, 93, 0.2);

      .eachPoint {
        padding: 0.5rem 0;

        .MuiCheckbox-root {
          padding-top: 0;

          &:hover {
            background: none;
          }
        }

        .MuiFormControlLabel-root {
          display: flex;
          align-items: flex-start;
        }

        .MuiTypography-root {
          font-size: 14px;
          line-height: 18px;
          color: #202124;
        }
      }
    }
  }

  .popupFooter {
    padding: 10px 20px;
    border-top: 1px solid rgba(0, 67, 93, 0.2);
  }
}

#signIn {
  min-height: 100vh;
  background-color: #faf4f0;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif !important;

  .top-right-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 40%;
    z-index: 1;
  }

  .bottom-left-img {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 40%;
    z-index: 1;
  }

  .loginCard {
    background: $primaryWhite;
    padding: 40px;
    max-width: 500px;
    margin: 10px auto;
    position: relative;
    box-sizing: border-box;
    z-index: 2;
    border-radius: 16px;
    box-shadow: 2px 2px 9px 0px rgba(0, 0, 0, 0.05);

    @include media-breakpoint-up(sm) {
      min-width: 450px;
    }

    @include media-breakpoint-down(sm) {
      min-width: 250px;
    }

    .headingPart {
      margin-bottom: 30px;
      text-align: center;

      h3 {
        font-size: clamp(1.1em, 1.25vw, 2em);
        font-weight: 600;
        margin: 0 0 5px 0;
        color: $primaryBlack;
        line-height: 100%;
      }

      p {
        margin: 0;
        font-size: clamp(0.9em, 0.83vw, 1.2em);
        color: $primaryBlack;
      }
    }

    .loginLogoBlock {
      text-align: center;
      width: 100%;
      margin-bottom: 2em;

      .loginLogo {
        width: 75%;
        margin: 0 auto;
      }
    }

    .formControl {
      margin-bottom: 20px;

      label {
        font-size: clamp(0.85em, 0.83vw, 1.06em) !important;
        padding-top: 3px;
        color: $primaryBlack;
      }

      input {
        height: 32px;
        padding: 5px 15px;
        color: #000000;
        font-size: clamp(0.8em, 0.72vw, 1.2em);
        font-family: "Poppins", sans-serif;
      }

      fieldset {
        border: 1px solid #e3ddd9;
        border-radius: 8px;
      }
    }

    .errorMessage {
      font-size: clamp(12px, 0.72vw, 16px) !important;
      font-family: "Poppins", sans-serif;
      color: #ff0000;
      font-weight: 500;
      height: auto !important;
    }

    .loginButton {
      padding: 10px 20px;
      font-family: "Poppins", sans-serif;
      color: #ffffff;
      margin-top: 2em;
      font-size: clamp(0.87em, 0.833vw, 1.12em);
      font-weight: 500;
      width: 100%;
      text-transform: capitalize;
      border-radius: 7px;
      background: linear-gradient(
        90deg,
        #cddc57 0.01%,
        #f19d5e 36.5%,
        #c53476 69.5%,
        #ae3a7a 100%
      );
    }
  }
}

#navbar {
  background: $primaryWhite;
  padding: 12px 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  border-bottom: 1px solid #4a4a4a3d;

  .navMenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
    margin: 0;

    li {
      padding: 0 !important;
      display: block !important;
      border-bottom: none;

      a {
        padding: 5px 15px;
        font-size: clamp(12px, 0.72vw, 16px);
        color: #4a4a4a;
        font-weight: 500;
        font-family: "Poppins", sans-serif;
        text-decoration: none;

        &:hover {
          text-decoration: none;
          background: none;
          color: #b03877;
        }
      }

      &.navLinkActive {
        a {
          color: #b03877 !important;
        }
      }

      &.loginDropdown {
        margin-left: 30px;
      }
    }
  }

  @media (max-width: 500px) {
    display: none !important;
  }
}

.primaryDashboard {
  padding-top: 5rem;
  padding-bottom: 1rem;

  @media (max-width: 500px) {
    padding-top: 0rem !important;
  }
}

.errorMessage {
  color: #ff0000;
  font-size: clamp(10px, 0.725vw, 14px) !important;
  height: 15px;
  margin-top: 5px;
}

#selectGeneType {
  .headingPart {
    h3 {
      font-size: 1em;
      color: $primaryColor;
      font-weight: 500;
    }
  }

  .gensPart {
    margin-top: 30px;
    background: #fff;
    padding: 30px;
    min-height: 400px;

    h5 {
      font-size: 1.2em;
      color: $primaryColor;
      margin: 0 0 20px 0;
    }
  }
}

.actionList {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  margin-right: 15px;

  li {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.reportEditTitle {
  font-weight: 500;
  font-size: 1.15em;
  margin: 0;
  padding: 0;
}

.edit-block {
  form {
    background: #fff;
  }

  table {
    border-collapse: collapse;
  }

  .editGenoTypeLable {
    font-size: clamp(11px, 0.625vw, 15px);
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
}

.customTablePagination {
  border: 1px solid #cfdddf !important;
  border-width: 1px 0 0 0 !important;
  border-top: 0;
  border-radius: 0 0 8px 8px;

  .PaginationCard {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 10px;
    position: sticky;
    bottom: 0;
    left: 0;

    .paginationGroup {
      display: flex;
      gap: 10px;
      align-items: center;

      .paginationText {
        font-family: "Poppins", sans-serif;
        font-size: clamp(12.5px, 0.72vw, 18px) !important;
        margin: 0;
      }

      .gotoField {
        .MuiInputBase-input {
          padding: 2px;
          width: 40px;
          height: 20px;
          font-size: clamp(12px, 0.72vw, 16px);
        }

        fieldset {
          border: 1px solid #e3ddd9;
        }
      }

      .rowPerPageSelect {
        height: 25px !important;
        border-radius: 3px !important;
        font-size: clamp(12px, 0.72vw, 16px);
        border: none;
        margin-top: 1px;
        font-family: "Poppins", sans-serif;

        fieldset {
          border: 1px solid #e3ddd9;
        }
      }
    }

    .pageNumber {
      font-family: "Poppins", sans-serif;

      .MuiButtonBase-root {
        height: 25px !important;
        min-width: 25px !important;
        font-weight: 400;
        font-size: clamp(12.5px, 0.72vw, 18px) !important;
        font-family: "Poppins", sans-serif !important;
        border-radius: 50%;

        &:hover {
          background: #fbdeda !important;
          color: #f05b43 !important;
          border-radius: 50%;
        }
      }

      .Mui-selected {
        background: #fbdeda !important;
        color: #f05b43;
        font-weight: 400;
        font-size: 14px;
        border-radius: 50%;

        &:hover {
          background: #fbdeda !important;
          color: #f05b43;
        }
      }
    }
  }
}

.menuItem {
  font-size: clamp(12px, 0.72vw, 16px) !important;
  font-family: "Poppins", sans-serif !important;
}

.customTable {
  margin-top: 1em;
  background-color: $primaryWhite;
  border-radius: 8px;
  overflow-x: auto;

  table {
    border-collapse: separate !important;
    overflow-x: auto;

    thead {
      tr {
        th {
          background: $secondary-color;
          border: 1px solid $primaryWhite !important;
          padding: 4px 10px;
          font-size: clamp(0.68em, 0.72vw, 1em) !important;
          font-weight: 500;
          font-size: 1em;
          color: $primaryWhite;
          font-family: "Poppins", Sans-serif;

          .headerWithSort {
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            .sortImageDefault {
              display: none;
            }

            &:hover {
              .sortImageDefault {
                display: block;
              }
            }
          }

          &:first-child {
            border-top-left-radius: 8px;
            position: sticky;
            left: 0;
            z-index: 3;
            background-color: $secondary-color;
          }

          &:nth-child(2) {
            position: sticky;
            left: 61.5px;
            z-index: 3;
            background-color: $secondary-color;
          }

          &:nth-child(3) {
            position: sticky;
            left: 183.1px;
            z-index: 3;
            background-color: $secondary-color;
          }

          &:nth-child(4) {
            position: sticky;
            left: 284.7px;
            z-index: 3;
            background-color: $secondary-color;
          }

          &:last-child {
            border-top-right-radius: 8px;
            position: sticky;
            right: 0;
            z-index: 3;
            background-color: $secondary-color;
          }
        }
      }
    }

    td {
      border-bottom: 1px solid #cfdddf;
      padding: 6px 10px;
      font-family: "Poppins", Sans-serif;
      font-size: clamp(0.68em, 0.72vw, 1em);
      color: $primaryBlack;

      &:first-child {
        position: sticky;
        left: 0;
        z-index: 2;
        background-color: $primaryWhite;
      }

      &:nth-child(2) {
        position: sticky;
        left: 61.5px;
        z-index: 2;
        background-color: $primaryWhite;
      }

      &:nth-child(3) {
        position: sticky;
        left: 183.1px;
        z-index: 2;
        background-color: $primaryWhite;
      }

      &:nth-child(4) {
        position: sticky;
        left: 284.7px;
        z-index: 2;
        background-color: $primaryWhite;
      }

      &:last-child {
        position: sticky;
        right: 0;
        z-index: 2;
        background-color: $primaryWhite;
      }
    }

    tr {
      &:nth-last-child(1) {
        td {
          border-bottom: 0 !important;
        }
      }
    }
  }

  .progressActionButton {
    border-radius: 4px;
    font-weight: 600;
    padding: 5px 10px;
    line-height: 1.5;
    font-family: "IBM Plex Sans", Sans-serif;
    min-width: 120px;
    font-size: 12px !important;
  }
}

.backButtonAction {
  button {
    border-radius: 5px;
    font-size: 1em;
    padding: 7px 10px !important;
    font-weight: 600;
    text-transform: uppercase;
    background: #4996d2;
    color: #fff !important;

    &:hover {
      background: #4996d2;
    }

    svg {
      width: 18px;
      height: 18px;
      padding: 3px;
      fill: #fff;
      color: #fff;
      margin-right: 5px;
    }
  }
}

.uploadDropdown {
  background: #00435d !important;
  color: #fff !important;
  padding: 5px 19px !important;
  border-radius: 5px !important;
}

//genrated repory summary
#summaryDatePicker {
  .MuiOutlinedInput-root {
    height: 30px;
    font-size: clamp(12px, 0.72vw, 16px);
    color: #000;

    .MuiSvgIcon-root {
      font-size: 14px;
    }
  }
}

#summaryDetails,
#organSpecificPage,
#dietaryGuidelines,
#weeklyDietPlanner,
#riskforGenetikDisorders {
  .editSummaryBlock {
    width: 100%;
    text-align: left;
  }

  .headingText {
    color: #f35a3e;
    font-family: "Poppins", sans-serif;
    font-size: clamp(14px, 0.93vw, 20px);
    font-weight: 600;
    line-height: 140%;
    text-transform: uppercase;
  }

  .editBtnGrp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    margin: 0 auto;
  }

  .editBtn {
    text-transform: capitalize !important;
    border: 1px solid #ae3a7a !important;
    color: #ae3a7a !important;
    min-width: inherit;
    padding: 2px 12px;
    font-size: clamp(12px, 0.72vw, 15px);
    border-radius: 4px;

    .MuiButton-startIcon {
      margin-left: 0;
      margin-right: 4px;
    }

    &:hover {
      background: transparent;
    }
  }

  .processSummaryBlock {
    width: 95%;
    margin: 0 auto;

    .summaryEditBtnGrp {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 1rem;
      margin-top: 1.5rem;

      .saveBtn {
        font-size: clamp(12.5px, 0.72vw, 16px);
        font-family: "Poppins", sans-serif;
        text-transform: capitalize;
        border-radius: 60px;
        background: #ae3a7a;
        border: 1px solid #ae3a7a;
        font-weight: 500;
        padding: 8px 25px;
        color: #fff;
      }

      .cancelBtn {
        border: 1px solid transparent;
        color: #ae3a7a;
        font-size: clamp(12.5px, 0.72vw, 16px);
        font-family: "Poppins", sans-serif;
        text-transform: capitalize;
        border-radius: 60px;
        font-weight: 500;
        padding: 8px 25px;

        &:hover {
          background: transparent;
        }
      }
    }
  }

  #editPatientDetailsBlock {
    .inputField {
      input {
        height: 28px;
        padding: 5px 15px;
        color: #000000;
        font-size: clamp(0.8em, 0.72vw, 1.2em);
        font-family: "Poppins", sans-serif;
      }

      fieldset {
        border: 1px solid #11111333;
        border-radius: 6px;
      }
    }

    .selectField {
      border-radius: 6px;
      font-size: clamp(0.8em, 0.72vw, 1.2em);
      font-family: "Poppins", sans-serif;

      .MuiSelect-select {
        padding: 7.5px 14px;
        font-size: clamp(0.8em, 0.72vw, 1.2em);
        font-family: "Poppins", sans-serif;
      }

      fieldset {
        border: 1px solid #11111333;
        border-radius: 6px;
      }
    }

    .patientEditBtnGtp {
      display: flex;
      align-items: center;
      gap: 1rem;

      .saveBtn {
        font-size: clamp(11px, 0.69vw, 15px);
        font-family: "Poppins", sans-serif;
        text-transform: capitalize;
        border-radius: 4px;
        background: #a3be3c;
        border: 1px solid #a3be3c;
        font-weight: 400;
        padding: 4px 10px;
      }

      .cancelBtn {
        border: 1px solid #f35a3e;
        color: #f35a3e;
        font-size: clamp(11px, 0.69vw, 15px);
        font-family: "Poppins", sans-serif;
        text-transform: capitalize;
        border-radius: 4px;
        font-weight: 400;
        padding: 4px 10px;
      }
    }
  }
}

#editGeneratedReportPageHeader {
  .mergeBtn {
    border-radius: 4px;
    background: rgba(205, 220, 87, 0.4);
    font-size: clamp(11px, 0.69vw, 15px);
    font-family: "Poppins", sans-serif;
    color: #000;
    text-transform: capitalize;
    min-width: inherit;
    padding: 3px 10px;

    .MuiButton-startIcon {
      margin-left: 0;
      margin-right: 4px;
    }
  }
}

#editPharmacoGenomicsBlock {
  flex-grow: 1;

  .inputField {
    width: 100%;
    border-bottom: 0 !important;

    .MuiSelect-select {
      font-family: "Poppins", sans-serif;
      padding: 3.5px 11px !important;
      font-size: clamp(0.75em, 0.72vw, 1.1em);
    }

    .MuiSvgIcon-root {
      width: 0.9em;
    }

    fieldset {
      border: 1px solid #11111333;
      border-radius: 4px;
    }
  }
}

#sideNavBar {
  .sideNavTab {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.05);
  }
}

.MuiStepLabel-iconContainer {
  div {
    height: 35px;
    width: 35px;
  }

  img {
    height: 20px;
    width: 20px;
  }

  // &.Mui-active  {

  // }
}

//Organic specific genetic risk page styles
#organSpecificPage {
  flex: 1;

  .previewBlock {
    .htmlPreview {
      background-color: #fff;
      padding: 1rem;
      border-radius: 10px;
    }
  }

  .OrganSpecificEditPage {
    background-color: #fff;
    padding: 3rem;
    border-radius: 10px;

    table {
      width: 100%;

      #Pharmacogenomics {
        td,
        th {
          border: 1px solid #000;
          padding: 6px 10px;
        }

        .MuiFormControlLabel-root {
          margin-left: 0;
        }

        .MuiButtonBase-root {
          padding: 0;
        }
      }
    }

    tfoot {
      td {
        border: 1px solid #000;
        border-width: 0 1px 1px 1px;

        .defaultFooter {
          width: 100%;

          .logoImage {
            width: 100%;
          }
        }
      }
    }
  }
}

//dietaryGuidelines page styles
#dietaryGuidelines {
  flex: 1;

  .previewBlock {
    width: 100%;

    .htmlPreview {
      background-color: #fff;
      padding: 2rem 1rem;
      border-radius: 10px;
      width: 95%;
      margin: 0 auto;
    }
  }

  #dietoryEditBlock {
    table {
      width: 100%;
      font-family: "Poppins", sans-serif;

      th {
        padding: 4px 10px;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: clamp(13px, 0.833vw, 18px);
        color: #fff;

        &:first-child {
          background-color: #f05b43;
          border-radius: 8px 0 0 0;
        }

        &:nth-child(2) {
          background-color: #e14146;
        }

        &:nth-child(3) {
          background-color: #a3be3c;
        }

        &:nth-child(4) {
          background-color: #f05b43;
          border-radius: 0 8px 0 0;
        }
      }

      td {
        background-color: #ffeadc;
        padding: 6px 10px;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: clamp(13px, 0.833vw, 18px);

        .inputField {
          width: 100%;

          input {
            background-color: #fff;
            border-radius: 4px;
            width: 100%;
            box-sizing: border-box;
            height: 42px;
            padding: 0 15px;
            color: #000000;
            font-size: clamp(0.8em, 0.72vw, 1.2em);
            font-family: "Poppins", sans-serif;
          }

          fieldset {
            border: none;
            border-radius: 4px;
          }
        }
      }

      .MuiButtonBase-root {
        padding: 0;
      }

      tfoot {
        td {
          border: none !important;
          text-align: right;

          // padding: 1rem 0 0 !important;
          button {
            font-size: clamp(11px, 0.69vw, 15px);
            font-family: "Poppins", sans-serif;
            text-transform: capitalize;
            border-radius: 4px;
            background: #a3be3c;
            border: 1px solid #a3be3c;
            font-weight: 400;
            padding: 3px 12px !important;
            color: #fff;
          }
        }
      }
    }
  }
}

//weekly diet planner page styles

#weeklyDietPlanner {
  flex: 1;

  .previewBlock {
    width: 100%;

    .htmlPreview {
      background-color: #fff;
      padding: 2rem 1rem;
      border-radius: 10px;
      width: 95%;
      margin: 0 auto;
    }
  }
}

//riskforGenetikDisorders page
#riskforGenetikDisorders {
  flex: 1;

  .previewBlock {
    width: 100%;

    .htmlPreview {
      background-color: #fff;
      padding: 2rem 1rem;
      border-radius: 10px;
      width: 95%;
      margin: 0 auto;
    }
  }

  .riskDisordersCKEditor {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 10px;
  }
}

//risk chronic disorders layout tab
#riskChronicLayout {
  .IframePreviewBlock {
    flex: 1;
    // background: #fff;
    padding: 1.5rem;
    border-radius: 10px;

    iframe {
      height: 70vh;

      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
        border-radius: 2px;
      }
    }
  }

  .editBtn {
    text-transform: capitalize !important;
    border: 1px solid #ae3a7a !important;
    color: #ae3a7a !important;
    min-width: inherit;
    padding: 2px 12px;
    font-size: clamp(12px, 0.72vw, 15px);
    border-radius: 4px;

    .MuiButton-startIcon {
      margin-left: 0;
      margin-right: 4px;
    }

    &:hover {
      background: transparent;
    }
  }

  .tableSelectComponent {
    border-radius: 6px;
    font-size: clamp(0.8em, 0.72vw, 1em);
    font-family: "Poppins", sans-serif;

    .MuiInputBase-root {
      font-size: clamp(0.81em, 0.83vw, 1.2em);
      font-family: "Poppins", sans-serif;
    }

    fieldset {
      border: 1px solid #11111333;
      border-radius: 6px;
    }
  }

  .updateBtnGrp {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 1rem;

    button {
      font-size: clamp(12.5px, 0.72vw, 16px);
      font-family: "Poppins", sans-serif;
      text-transform: capitalize;
      border-radius: 60px;
      background: #ae3a7a;
      border: 1px solid #ae3a7a;
      font-weight: 500;
      padding: 8px 25px;
      color: #fff;
    }
  }
  .editBtnGrp {
    padding: 1rem 0;
  }
}

//nutri genomics
#nutriGenomicsView {
  flex: 1;

  .previewBlock {
    .htmlPreview {
      height: 80vh;
      overflow: auto;

      background-color: #fff;
      padding: 1rem;
      border-radius: 10px;
    }
  }

  .editNutriGenomicsBlock {
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 10px;

    .pageHeading {
      h6 {
        color: #f35a3e;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: clamp(16px, 1.04vw, 22px);
      }
    }

    .tableTextField {
      input {
        height: 26px;
        padding: 5px 15px;
        color: #000000;
        font-size: clamp(0.7em, 0.69vw, 0.9em);
        font-family: "Poppins", sans-serif;
      }

      fieldset {
        border: 1px solid #11111300;
        border-radius: 6px;
      }
    }

    .tableSelectField {
      border-radius: 6px;
      font-size: clamp(1em, 0.69vw, 1.2em);
      font-family: "Poppins", sans-serif;

      .MuiSelect-select {
        padding: 10.5px 14px;
        font-size: clamp(1em, 0.69vw, 1.2em);
        font-family: "Poppins", sans-serif;
      }

      fieldset {
        border: 1px solid #11111300;
        border-radius: 6px;
      }
    }

    .UpdateBtnGroup {
      text-align: end;
      margin-top: 1rem;
    }
  }

  .headingText {
    color: #f35a3e;
    font-family: "Poppins", sans-serif;
    font-size: clamp(14px, 0.93vw, 20px);
    font-weight: 600;
    line-height: 140%;
    text-transform: uppercase;
  }
  .editBtnGrp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
    margin: 0 auto;
  }

  .editBtn {
    text-transform: capitalize !important;
    border: 1px solid #ae3a7a !important;
    color: #ae3a7a !important;
    min-width: inherit;
    padding: 2px 12px;
    font-size: clamp(12px, 0.72vw, 15px);
    border-radius: 4px;

    .MuiButton-startIcon {
      margin-left: 0;
      margin-right: 4px;
    }

    &:hover {
      background: transparent;
    }
  }
}

//summary page
#summaryPage {
  .summarySearch {
    background-color: #fff;
    border-radius: 6px;

    input {
      height: 26px;
      padding: 5px 15px 5px 0;
      color: #000000;
      font-size: clamp(0.7em, 0.69vw, 0.9em);
      font-family: "Poppins", sans-serif;
      border-radius: 6px;
    }

    fieldset {
      border: 1px solid #1111131d;
      border-radius: 6px;
    }
  }

  #users-table {
    .summaryPageTable {
      border: 0;
      font-family: "Poppins", sans-serif;

      .MuiDataGrid-columnHeaderTitleContainerContent {
        width: 100%;
        color: #fff;
      }

      .MuiDataGrid-columnHeaders {
        background: #f35a3e;
        min-height: 32px !important;
        max-height: 32px !important;
        line-height: 32px !important;
        color: #fff;
      }

      .MuiDataGrid-footerContainer {
        display: none;
      }

      .MuiDataGrid-row {
        background: white;
      }

      .MuiDataGrid-cell--withRenderer {
        border-block: 1px solid #c6d1dd51 !important;
      }

      .MuiDataGrid-row:hover {
        background: #f8fbff;
      }

      .MuiDataGrid-columnHeaderTitle {
        font-size: clamp(11px, 0.62vw, 16px);
        font-weight: 400;
        color: #fff;
        font-family: "Poppins", Sans-serif;
      }

      .MuiDataGrid-cell:focus {
        outline: 0;
      }

      .MuiDataGrid-columnHeader:focus {
        outline: 0;
      }

      .MuiDataGrid-columnHeader {
        border-left: 0.0625rem solid #fff !important;

        &:first-child {
          border: 0px solid #fff !important;
        }
      }

      .MuiDataGrid-sortIcon {
        color: #ffffff;
      }
    }

    .summaryNoDataBlock {
      display: flex;
      justify-content: center;
      z-index: 1;

      .noSummaryImgBlock {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .noDataText {
          font-size: clamp(18px, 2.72vw, 22px);
          color: #000;
        }
      }
    }
  }

  .paginationSummary {
    .MuiButtonBase-root {
      font-family: "Poppins", sans-serif;
      height: 25px !important;
      min-width: 25px !important;
      font-weight: 400;
      font-size: clamp(12.5px, 0.72vw, 18px) !important;
      border-radius: 50%;

      &:hover {
        background: #fbdeda !important;
        color: #f05b43 !important;
        border-radius: 50%;
      }
    }

    .Mui-selected {
      background: #fbdeda !important;
      color: #f05b43;
      font-weight: 400;
      font-size: 14px;

      &:hover {
        background: #fbdeda !important;
        color: #f05b43;
      }
    }
  }
}

.cellAction {
  font-size: clamp(12px, 0.62vw, 16px) !important;
  font-family: "Poppins", Sans-serif !important;
  font-weight: 400 !important;
  color: #464f60;
  width: 100% !important;
  display: flex !important;
  align-items: center !important;

  p {
    margin: 0 !important;
  }
}

.editErrorMessage {
  font-size: clamp(11px, 0.693vw, 16px) !important;
  font-family: "Poppins", sans-serif;
  color: #ff0000;
  font-weight: 500;
  height: auto !important;
}

.abbreviations {
  padding: 1rem 1rem 1rem 1rem;

  h5 {
    font-size: 1.1rem !important;
    font-weight: 500 !important;
    margin-bottom: 10px;
    color: #e91e63 !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    padding-bottom: 5px;
  }

  input {
    height: 30px !important;
    padding: 5px 10px !important;
  }

  .actionButton {
    display: flex;
    justify-content: flex-end;

    .add-more-button {
      padding: 2px 5px;
    }
  }
}

// buttons

.defaultButton {
  font-size: clamp(12.5px, 0.72vw, 16px);
  font-family: "Poppins", sans-serif;
  text-transform: capitalize;
  border-radius: 60px !important;
  font-weight: 500;
  padding: 8px 25px;
  color: #fff;
  min-width: 100px !important;

  &.updateButton {
    background: #ae3a7a;
    border: 1px solid #ae3a7a;

    &:hover {
      background: #ae3a7a;
      border: 1px solid #ae3a7a;
    }
  }

  &.cancelButton {
    background: #f05b43;
    border: 1px solid #f05b43;
    color: #fff;

    &:hover {
      background: #f05b43;
      border: 1px solid #f05b43;
    }
  }

  &.cancelBtn {
    color: #f05b43;
    background-color: #fff;
    border-radius: 6px !important;
    border: 1px solid #f05b43;

    &:hover {
      color: #f05b43;
      background-color: #fff;
    }
  }

  &.addButton {
    background: #a3be3c;
    border: 1px solid #a3be3c;
    color: #fff;
    border-radius: 6px !important;

    &:hover {
      background: #a3be3c;
      border: 1px solid #a3be3c;
    }
  }

  &.clearBtn {
    padding: 5px 10px;
    font-size: 13px;
    line-height: 12px;
    height: 22px;
    border: 1px solid #f05b43;
    background-color: #f05b43;
    color: #fff;
    min-width: 70px !important;

    &:hover {
      border: 1px solid #f05b43;
      background-color: #f05b43;
    }
  }

  &.saveBtn {
    padding: 5px 10px;
    font-size: 13px;
    line-height: 12px;
    height: 22px;
    color: #fff;
    border: 1px solid #a3be3c;
    background-color: #a3be3c;
    min-width: 70px !important;

    &:hover {
      border: 1px solid #a3be3c;
      background-color: #a3be3c;
    }
  }
}

.periodic-tests-edit {
  table {
    thead tr th {
      background: #f05b43 !important;
      font-weight: 500 !important;

      &:nth-child(4) {
        border-radius: 0 !important;
      }

      &:last-child {
        border-radius: 0 8px 0 0;
      }
    }

    tfoot {
      td {
        padding: 10px !important;
        border-radius: 0 0 8px 8px;
      }
    }

    td {
      &:nth-child(4) {
        width: 200px;
        max-width: 200px;
      }
    }
  }

  .custom-frequency {
    background: #ffffff;

    input {
      border-right: 1px solid rgba(202, 202, 202, 0.3) !important;
      border-radius: 0;
      text-align: center;
    }
  }
}
.rishDisorder_pdf {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 15px;
  border: 1px solid #cacaca;
}

// Import Components
@import "../src/components/scss/patientReports.scss";
