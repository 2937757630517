.dietaryBlock{
    align-self: stretch;
      border-radius: 16px;
      background-color: #fff;
      border: 1px solid #cacaca;
      align-items: center;
      justify-content: center;
      padding: 1.5rem;
      gap: 1rem;
    }
