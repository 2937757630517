.form {
  margin: 0;
  gap: 0.5rem;
}

.form,
.formcontainer {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.formcontainer {
  gap: 1rem;
}

.addsummaryreportform {
  flex: 1;
  border-radius: 16px;
  background-color: #fff;
  overflow: hidden;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.addsummaryreportform .updatesubmitButton {
  border: 1px solid #069;
  color: #fff;
  background: #069;
}

.addsummaryreportform .updatesubmitButton:hover {
  border: 1px solid #069;
  color: #fff;
  background: #069;
}

.addsummaryreportform .updateButton {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}