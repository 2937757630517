/* .select,
.select1 {
  width: 12.688rem;
  font-family: Poppins;
  font-size: 0.75rem;
  color: #bdbdbd;
}

.select1 {
  border: 0;
  background-color: transparent;
  width: 17.563rem;
  font-size: 0.875rem;
} */

.filteroptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}