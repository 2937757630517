.deleteDialog {
    max-width: 500px;
    margin: 0 auto;
}

.canceldeletePopup .confirmDelete {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: clamp(22px, 1.14vw, 24px);
    letter-spacing: 0px;
    font-weight: 400;
}

.canceldeletePopup .deleteButton {
    background: #ED5E68;
    text-decoration: none;
    font-family: "IBM Plex Sans", sans-serif;
}

.canceldeletePopup .deleteButton:hover {
    background: #ED5E68;
}

.canceldeletePopup {
    padding: 5px;
}

.canceldeletePopup .cancelButton {
    text-decoration: none;
    font-family: "IBM Plex Sans", sans-serif;
    background: #fff;
    color: #ED5E68;
    border: 1px solid #ED5E68;
    gap: 3px;
}

.canceldeletePopup .cancelButton:hover {
    background: #fff;
    color: #ED5E68;
    border: 1px solid #ED5E68;
}

.canceldeletePopup .sureyouwanttoDelete {
    font-family: "IBM Plex Sans", sans-serif;
    font-size: clamp(14px, 0.72vw, 18px);
    font-weight: 400;
}

.deleteDialog .title {
    display: flex;
    align-items: center;
    column-gap: var(--gap-7xs);
}