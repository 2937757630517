.icon {
  width: 0.75rem;
  position: relative;
  height: 0.75rem;
  overflow: hidden;
  flex-shrink: 0;
}

.uploadedFile {
  border: #71ebb3 1px dotted;
  border-radius: 1rem;
  padding: .8rem 1rem .8rem 1rem;

  display: flex;
  justify-content: space-between;
  width: 400px;
}

.uploadedFileDetails {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem
}

.uploadedFileDetails p {
  word-break: break-all;
  font-size: clamp(14px, 0.833vw, 18px);
}

.iconcontainer {
  border-radius: 18px;
  background: linear-gradient(145.4deg, #006a7e 0.36%, #71ebb3);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.38rem;
}

.personalDetails {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: inherit;
  font-size: clamp(14px, 0.93vw, 20px);
  font-weight: 400;
  font-family: "IBM Plex Sans", sans-serif;
  color: #069;
}

.inputElement {
  width: 100%;
}

.headinglable,
.upload {
  align-self: stretch;
  display: flex;
  align-items: center;
}

.headinglable {
  flex-direction: row;
  justify-content: flex-start;
  gap: 0.5rem;
}

.upload {
  border-radius: 6px;
  background-color: #fff;
  border: 1px dashed #00435d;
  overflow: hidden;
  background-color: #edf6fc;
  justify-content: center;
  padding: 1rem 0;
}

.hinttext {
  margin: 0;
  flex: 1;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 1.25rem;
}

.badges,
.reports {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  color: #dc2626;
}

.reports {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.63rem;
  text-align: left;
  font-size: 1.13rem;
  color: #069;
  font-family: "IBM Plex Sans";
}