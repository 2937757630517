#UpdatePassword {
  padding: 2em 0;
  background: #f5f5f5;
}

#UpdatePassword .update-inner-block .card-block {
  width: 350px;
  margin: 0 auto;
  background: #fff;
  border-radius: 10px;
  padding: 30px;
}

#UpdatePassword .update-inner-block .card-block .card-inner .update-btn {
  padding: 7px 20px !important;
}
