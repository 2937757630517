.heading,
.page1Icon,
.page2Icon {
  align-self: stretch;
  position: relative;
}
.heading {
  margin: 0;
  font-size: inherit;
  letter-spacing: -0.02em;
  font-weight: 500;
  font-family: inherit;
}
.page1Icon,
.page2Icon {
  max-width: 100%;
  overflow: hidden;
  height: 22.75rem;
  flex-shrink: 0;
  object-fit: cover;
}
.page2Icon {
  height: 22.81rem;
}
.cardcontainer,
.preview {
  align-self: stretch;
  display: flex;
  flex-direction: column;
}
.cardcontainer {
  border-radius: 15px;
  background-color: #fff8ee;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;
}
.preview {
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
}
.calncelbutton {
  flex: 1;
}
.buttongroup {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 1.25rem;
}
.summaryreportHeading{
  color: #ED8818;
 font-family: "IBM Plex Sans", sans-serif;
 font-size: clamp(14px,0.72vw, 18px);
 font-weight: 500;
}
.previewcontainer {
  flex: 1;
  border-radius: 16px;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 1rem;
  box-sizing: border-box;
  gap: 0.63rem;
  max-width: 37.5rem;
  text-align: left;
  font-size: 1rem;
  color: #ff583e;
  font-family: "IBM Plex Sans";
}
.previewcontainer .printButton{
  color:#ED8818;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  border: 1px solid #ED8818 ;
 
}
.previewcontainer .printButton:hover{
  color: #fff;
  background: #e7b275;
  border: 1px solid #ED8818;
}
.previewcontainer .saveButton{
  color:#fff;
  background: #ED8818;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 500;
  border-radius: 5px;
}
.previewcontainer .saveButton:hover{
  color:#fff;
  background: #ED8818;
  font-weight: 500;
  border-radius: 5px;
}
.warningButtonComponent{ 
    display: flex;
    gap: 1rem;
    justify-content: center !important;
    padding: 1.5rem 0 0 0 ;
    width:90%;
    

}
.warningText{
  font-size: clamp(26px,1.35vw,28px);
    color: #f0391c;
    font-weight:500;
    font-family: "IBM Plex Sans", sans-serif;
}
.warningButtonComponent .warningCancelButton{
  border-radius: 6px;
  border: 2px solid #FF583E;
  color: #FF583E;
font-family: "IBM Plex Sans", sans-serif;
font-size: clamp(14px, 0.72vw, 18px);
font-weight: 500;
width:40%;
}
.warningButtonComponent .warningCancelButton:hover{
  border-radius: 6px;
  border: 2px solid #FF583E;
  color: #FF583E;
}
.warningButtonComponent .warningConfirmButton{
  border-radius: 6px;
background: #069;
color: #fff;
font-weight: 500;
font-family: "IBM Plex Sans", sans-serif;
width:40%;
}
.summaryWarningCard{
  
    background: #ffe3df;
    min-height: 18rem;
    min-width: 30rem;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
 
}
.warningButtonComponent .warningConfirmButton:hover{
  border-radius: 6px;
  background: #069;
  color: #fff;
}