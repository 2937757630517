.icon {
  width: 0.75rem;
  position: relative;
  height: 0.75rem;
  overflow: hidden;
  flex-shrink: 0;
}

.iconcontainer {
  border-radius: 18px;
  background: linear-gradient(145.4deg, #006a7e 0.36%, #71ebb3);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flSSex-start;
  padding: 0.38rem;
}

.personalDetails {
  margin: 0;
  flex: 1;
  position: relative;
  font-size: clamp(14px, 0.93vw, 20px);
  font-weight: 400;
  font-family: "IBM Plex Sans", sans-serif;
  color: #069;
}

.personaldetails .getdetailsButton {
  color: #069;
  background: #E2F3FC;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  font-family: "IBM Plex Sans", Sans-serif;
  border: 0;
  height: 30px !important;
}

.personaldetails .getdetailsButton:hover {
  background: #E2F3FC;
  font-size: 14px;
  border: 0;
}

.headinglable {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5rem;
}

.lable {
  cursor: pointer;
  flex: 1;
  position: relative;
  font-size: clamp(12px, 0.72vw, 16px);
  font-family: "IBM Plex Sans", sans-serif;
  color: #000;
  line-height: 100%;
  font-weight: 600;
}

.lablecontainer {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.inputField {
  border: 0;
  background-color: transparent;
  align-self: stretch;
}

.visitid {
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;
}

.name,
.visitanduhid,
.visitid {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.visitanduhid {
  align-self: stretch;
  flex-direction: row;
  gap: 1rem;
}

.name {
  flex: 1;
  flex-direction: column;
  gap: 0.5rem;
}

.dropdown {
  align-self: stretch;
}

.inputgroup,
.personaldetails {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
  font-size: 0.88rem;
  color: #000;
}

.personaldetails {
  text-align: left;
  font-size: 1.13rem;
  color: #069;
  font-family: "IBM Plex Sans";
}

.changeButton {
  color: #069;
  background: #E2F3FC;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
  font-family: "IBM Plex Sans", Sans-serif;
  border: 0;
  height: 30px !important;
}