.mainContainer{
    width: 100%;
    min-height:100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.detailsContainer{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap:.7rem;
        position: sticky;
    
}

.details{
    display: flex;
    gap: 1rem;
}
