.edit-block {
  width: 100%;
  max-width: 1500px;
  margin: 0 auto !important;
}

.edit-container {
  flex: 1;
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
}
.edit-card {
  height: 80%;
  width: 100%;
  margin: auto;
  background: none !important;
  box-shadow: none !important;
  border: 1px solid #f05b43;
  border-radius: 20px;
  margin-bottom: 1rem;
}

.Patient-info {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #000 !important;
}

.Patient-info .MuiOutlinedInput-notchedOutline {
  border: 0;
}

.rmdp-input {
  border: 0 !important;
}

.Patient-info input {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.4); */
  padding-left: 5px;
  font-size: 13px;
  font-weight: 600;
  background: none !important;
  line-height: 16px;
}

.small-editcard h2 {
  color: #fff !important;
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  background: #2eaf9f;
  text-align: center;
  width: 100%;
  height: 40px;
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custm-input {
  max-width: 100%;
  flex-basis: 100%;
}

.infoBlock {
  width: 100%;
}

.item {
  display: flex;
}

.inputname p {
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #000;
  padding-left: 10px;
}

.inputname {
  background: #e0f3f1;
  border-bottom: 1px solid #e0f3f1;
  width: 41%;
  display: flex;
  align-items: center;
}

.inputField {
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  width: 58%;
}

.rmdp-input:focus {
  box-shadow: none !important;
}

.inputField .MuiFormControl-root {
  background: transparent !important;
}

.categoryDetail {
  background: #e0f3f1;
  text-align: center;
  border-radius: 5px;
  margin: 5px 5px 0 5px !important;
  padding: 6px 0;
  height: 30px;
}

.categoryDetail h6 {
  font-weight: 600;
  font-size: 18px;
  color: #000;
}

.macronutrient-block {
  margin: 0px;
  box-shadow: none !important;
  background: none !important;
  border: 1px solid #cacaca;
  border-radius: 4px 4px 0 0;
  font-family: "Poppins", sans-serif;
}
.macronutrient-block table {
  width: 100%;
}
/* .macronutrient-block .heading {
  font-weight: 500;
  font-size: clamp(14px, 1vw, 21px);
  padding: 0 10px;
  color: #000;
  margin: 10px 0 20px;
} */

.macronutrient-block th:not(:last-child) {
  border-right: 5px solid #ffffff;
}

.macronutrient-block td:not(:last-child) {
  border-right: 5px solid #ffffff;
}

.macronutrient-block th {
  background: #c9bbe3;
  font-size: clamp(12px, 0.72vw, 17px);
  border-bottom: 5px solid #ffffff;
  padding: 8px 10px;
  text-align: left !important;
  font-weight: 500;
  color: #000;
  line-height: 1.7;
  border-radius: 2px;
  border-collapse: separate;
  font-family: "Poppins", sans-serif;
}

.macronutrient-block td {
  vertical-align: top;
  background-color: #f5f5f5;
  border-bottom: 5px solid #ffffff;
  line-height: 1.3;
  padding: 8px 10px;
  font-size: clamp(12px, 0.72vw, 17px);
  text-align: left !important;
  font-weight: 400;
  color: #000;
  line-height: 1.7;
  border-radius: 2px;
  border-collapse: separate;
  font-family: "Poppins", sans-serif;
}

.macronutrient-block table tbody td:nth-child(1) {
  font-weight: 600 !important;
}

.macronutrient-block .MuiOutlinedInput-notchedOutline {
  border: 0;
}

/* gene types block styles */
.genes-block {
  padding: 1rem;
}
.gene-heading-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.gene-heading {
  font-weight: 400 !important;
  font-size: clamp(17px, 1.04vw, 22px) !important;
  color: #000 !important;
  font-family: "Poppins", sans-serif !important;
}

.form-fields {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5rem;
  margin-top: 1rem;
}

.form-fields .form-heading {
  font-size: 13px !important;
  font-family: "Raleway", sans-serif !important;
  font-weight: 500 !important;
}

.update-btn {
  text-transform: capitalize !important;
  margin-bottom: 1rem !important;
  background: #a3be3c !important;
  border: 1px solid #a3be3c !important;
  padding: 3px 10px !important;
  font-size: clamp(14px, 0.93vw, 20px) !important;
  font-family: "Poppins", sans-serif !important;
}

.styled-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

.styled-table th,
.styled-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.styled-table th {
  text-align: left;
  background-color: #f2f2f2;
}

.styled-table .header {
  text-align: center;
}

.styled-table img {
  display: block;
  width: 50px;
  /* Adjust the width as needed */
  height: auto;
  /* Maintain aspect ratio */
}
.tdvalue {
  color: #000000;
  font-size: 13px;
  font-weight: 600;
}
